import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Alert, Button, ButtonProps } from '@mui/joy';
import { DefaultColorPalette } from '@mui/joy/styles/types';

import { useEffect, useRef, useState } from 'react';

import { cn } from '@/utils/tailwind';

export function ExpandableErrorAlert(props: {
  error: unknown;
  color?: DefaultColorPalette;
  defaultOpen?: boolean;
  rightButtonProps?: ButtonProps & { label: string };
}) {
  const [open, setOpen] = useState(props.defaultOpen ?? false);
  const spanRef = useRef<HTMLSpanElement>(null);
  const [expandVisible, setExpandVisible] = useState(false);
  const error = props.error?.toString();

  useEffect(() => {
    function recomputeExpandVisible() {
      if (spanRef.current == null) return;
      const span = spanRef.current;
      setExpandVisible(
        span.offsetWidth < span.scrollWidth || (error?.includes('\n') ?? false)
      );
    }

    if (spanRef.current == null) return;
    const resizeObserver = new ResizeObserver(recomputeExpandVisible);
    resizeObserver.observe(spanRef.current);
    recomputeExpandVisible();
  }, [error]);

  if (props.error == null) return null;
  return (
    <Alert
      color={props.color ?? 'danger'}
      variant="outlined"
      className="grid-cols-[1fr,auto]"
      sx={{ display: 'grid', alignItems: 'flex-start' }}
    >
      <span
        ref={spanRef}
        className={cn(
          'flex-grow leading-6',
          open
            ? 'overflow-x-hidden whitespace-pre-wrap break-words'
            : 'overflow-hidden text-ellipsis whitespace-nowrap'
        )}
      >
        {error}
      </span>
      {props.rightButtonProps && (
        <Button
          {...props.rightButtonProps}
          color={props.color ?? 'danger'}
          variant="outlined"
        >
          {props.rightButtonProps.label}
        </Button>
      )}
      <button
        type="button"
        className={cn(
          'flex-shrink-0 p-1',
          // we only hide the element, but still preserve the space to prevent
          // excessive resize observer triggers
          expandVisible || open
            ? 'opacity-100'
            : 'pointer-events-none opacity-0'
        )}
        onClick={() => setOpen((v) => !v)}
      >
        <ChevronRightIcon
          className={cn('h-4 w-4 transition duration-200', open && 'rotate-90')}
        />
      </button>
    </Alert>
  );
}
