import '@fontsource/public-sans';
import { CssBaseline, CssVarsProvider, extendTheme } from '@mui/joy';

import { ReactNode } from 'react';

const theme = extendTheme({
  fontFamily: {
    body: `"Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")`,
    display: `"Inter", "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol")`,
  },
  shadow: {
    xs: 'var(--joy-shadowRing, 0 0 #000), 0 1px 2px 0 rgba(var(--joy-shadowChannel, 187 187 187) / 0.12)',
    sm: 'var(--joy-shadowRing, 0 0 #000), 0px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11), 0px 1.3px 1.8px -0.6px rgba(var(--joy-shadowChannel, 187 187 187) / 0.18), 0px 2.7px 3.8px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.26)',
    md: 'var(--joy-shadowRing, 0 0 #000), 0px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.12), 0px 2.8px 3.9px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.17), 0px 6.1px 8.6px -0.8px rgba(var(--joy-shadowChannel, 187 187 187) / 0.23),  0px 13.3px 18.8px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.29)',
    lg: 'var(--joy-shadowRing, 0 0 #000), 0px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11), 0px 4.5px 6.4px -0.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.13), 0px 7.9px 11.2px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.16), 0px 12px 17px -0.5px rgba(var(--joy-shadowChannel, 187 187 187) / 0.19), 0px 17.5px 24.7px -0.7px rgba(var(--joy-shadowChannel, 187 187 187) / 0.21)',
    xl: 'var(--joy-shadowRing, 0 0 #000), 0px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11), 0px 4.5px 6.4px -0.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.13), 0px 7.9px 11.2px -0.4px rgba(var(--joy-shadowChannel, 187 187 187) / 0.16), 0px 12px 17px -0.5px rgba(var(--joy-shadowChannel, 187 187 187) / 0.19), 0px 17.5px 24.7px -0.7px rgba(var(--joy-shadowChannel, 187 187 187) / 0.21), 0px 25.5px 36px -0.9px rgba(var(--joy-shadowChannel, 187 187 187) / 0.24), 0px 36.8px 52.1px -1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.27), 0px 52.3px 74px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.29)',
  },
  components: {
    JoyModal: {
      styleOverrides: {
        root: {
          'div[role="dialog"]': {
            backgroundColor: 'rgb(var(--bg-primary), 1)',
          },
        },
        backdrop: {
          filter: 'none',
          backdropFilter: 'none',
          background: 'rgba(var(--bg-primary), 0.8)',
        },
      },
    },
    JoyTooltip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(var(--text-primary))',
          color: 'rgb(var(--bg-secondary))',
          border: '1px solid rgb(var(--text-secondary))',
          '& .MuiTooltip-arrow': {
            color: '#131316',
          },
        },
      },
    },
  },
  colorSchemes: {
    dark: {
      palette: {
        primary: {
          50: '#D6F2EE',
          100: '#ACE3DD',
          200: '#7ACEC6',
          300: '#4FB2AD',
          400: '#359793',
          500: '#287977',
          600: '#246161',
          700: '#204F4F',
          800: '#1C3C3C',
          900: '#0D2427',
        },
        neutral: {
          50: '#FAFAFA',
          100: '#F4F4F5',
          200: '#E4E4E7',
          300: '#D1D1D6',
          400: '#A0A0AB',
          500: '#70707B',
          600: '#51525C',
          700: '#3F3F46',
          800: '#26272B',
          900: '#1A1A1E',
        },
        background: {
          body: '#131316',
        },
      },
    },
    light: {
      palette: {
        primary: {
          50: '#D6F2EE',
          100: '#ACE3DD',
          200: '#7ACEC6',
          300: '#4FB2AD',
          400: '#359793',
          500: '#287977',
          600: '#246161',
          700: '#204F4F',
          800: '#1C3C3C',
          900: '#0D2427',
        },
        neutral: {
          50: '#FAFAFA',
          100: '#F4F4F5',
          200: '#E4E4E7',
          300: '#D1D1D6',
          400: '#A0A0AB',
          500: '#70707B',
          600: '#51525C',
          700: '#3F3F46',
          800: '#26272B',
          900: '#1A1A1E',
        },
      },
    },
  },
});

export function BaseApp(props: { children?: ReactNode }) {
  return (
    <CssVarsProvider
      theme={theme}
      defaultMode="system"
      modeStorageKey="langsmith-app-joy-mode"
    >
      <CssBaseline />
      {props.children}
    </CssVarsProvider>
  );
}
