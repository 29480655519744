import { datadogLogs as _datadogLogs } from '@datadog/browser-logs';

import { datadogClientToken, datadogSite } from '../constants';

export let desktopDatadogLogs: typeof _datadogLogs | undefined = undefined;

export const initDesktopDatadog = (version: string) => {
  if (desktopDatadogLogs) return;
  if (datadogClientToken && datadogSite) {
    _datadogLogs.init({
      clientToken: datadogClientToken,
      site: datadogSite,
      env: 'desktop',
      service: 'studio',
      version,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      allowFallbackToLocalStorage: true,
    });

    desktopDatadogLogs = _datadogLogs;
  }
};
