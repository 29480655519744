import { type ClassValue, clsx } from 'clsx';
import type { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export type TailwindAttributes<E extends HTMLElement = HTMLDivElement> = {
  className?: ClassValue;
} & HTMLAttributes<E>;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const buttonHover = cn('transition-colors hover:bg-secondary');

export const utils = {
  buttonHover,
  button: cn(buttonHover, 'cursor-pointer p-1'),
  buttonActive: cn('bg-tertiary '),
};
