import { cn } from '@/utils/tailwind';

import LogoBlack from './LogoBlack.svg?react';
import LogoBlackLarge from './LogoBlackLarge.svg?react';

export function Logo(props: {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
}) {
  if (props.size === 'xl')
    return (
      <LogoBlackLarge className={props.className} width="233" height="38" />
    );
  if (props.size === 'lg')
    return <LogoBlackLarge className={cn('h-6 w-[162px]', props.className)} />;
  if (props.size === 'md')
    return <LogoBlackLarge className={cn('h-5 w-[130px]', props.className)} />;
  if (props.size === 'xs')
    return <LogoBlack className={cn('w-[30px]', props.className)} />;
  return <LogoBlack className={cn('h-6 w-[48px]', props.className)} />;
}
