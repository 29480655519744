import { HTMLAttributes } from 'react';

import { handleBasename } from './handle-basename';

// All vars in the window object are not present in other places / HTML,
// but this does disable undesired tree shaking after environment variable has been replaced during build
/* eslint-disable @typescript-eslint/no-namespace */
declare global {
  interface Window {
    backend_auth_type: string;
    basic_auth_enabled: string;
    single_origin_enabled: string;
    host_enabled: string;
    graph_enabled: string;
    host_private_repo_enabled: string;
    base_url: string;
    studio_local_graph_url: string;
    studio_custom_headers: string;
    ff_enable_few_shot_corrections: string;
    oauth_client_id: string;
  }
}

type StencilToReact<T> = {
  [P in keyof T]?: T[P] &
    Omit<HTMLAttributes<Element>, 'className'> & {
      class?: string;
    };
};

declare module 'react' {
  namespace JSX {
    interface IntrinsicElements {
      'launchnotes-embed-inline': StencilToReact<any> & {
        ref?: React.Ref<HTMLElement>;
      };
    }
  }
}

export const launchNotesEmbedToken = 'public_6qNyEFaFYqTtzDTf5ouHGJI5';

export const baseUrl = window.base_url ?? import.meta.env.BASE_URL ?? '/';

// All env variables we need to replace at runtime must be strings to avoid undesired tree shaking.
// Do NOT mix expressions comparing import.meta.env.VITE_... with other values, because Vite will resolve the
// value prematurely so our entrypoint will not replace at runtime, leaving us with the static string literal e.g. 'VITE_VAR'.
// For example, this will not work as expected: const var = window.basic_auth_enabled === 'true' || import.meta.env.VITE_BASIC_AUTH_ENABLED === 'true'.
// Instead, set constants to strings and compare them as strings separately.
export const backendAuthType:
  | 'mixed'
  | 'supabase'
  | 'oauth'
  | 'desktop'
  | (string & { ignore_me?: never }) =
  window.backend_auth_type ?? import.meta.env.VITE_BACKEND_AUTH_TYPE ?? 'none';

// Use true/false for consistency because this env var is used in other services as well
const basicAuthEnabledInternal =
  window.basic_auth_enabled ??
  import.meta.env.VITE_BASIC_AUTH_ENABLED ??
  'false';
export const basicAuthEnabled =
  backendAuthType === 'mixed' && basicAuthEnabledInternal === 'true';

export const oauthClientId =
  window.oauth_client_id ?? import.meta.env.VITE_OAUTH_CLIENT_ID ?? '';
export const customOidcEnabled =
  backendAuthType === 'mixed' && oauthClientId !== '';

export const mixedLoginMethods = {
  customOidc: customOidcEnabled,
  basic: basicAuthEnabled,
};

// note: if singleOriginEnabled = '1', this should be equal to an empty string
export const backendUrl =
  import.meta.env.VITE_BACKEND_URL ?? 'http://127.0.0.1:1984';

export const playgroundBaseUrl =
  import.meta.env.VITE_PLAYGROUND_BASE_URL ?? '/api/playground';

export const singleOriginEnabled =
  window.single_origin_enabled ||
  import.meta.env.VITE_SINGLE_ORIGIN_ENABLED ||
  '0';

export const degradedPerformanceEnabled =
  import.meta.env.VITE_DEGRADED_PERFORMANCE_ENABLED === '1';
export const apiPath =
  singleOriginEnabled === '1' ? handleBasename('/api/v1', baseUrl) : '';

export const platformApiPath =
  singleOriginEnabled === '1'
    ? handleBasename('/api/v1/platform', baseUrl)
    : '/v1/platform';
export const apiLoginPath = `${apiPath}/login`;
export const apiInfoPath = `${apiPath}/info`;
export const apiHealthPath = `${apiInfoPath}/health`;
export const apiTenantsPath = `${apiPath}/tenants`;
export const apiWorkspacesPath = `${apiPath}/workspaces`;
export const apiOrganizationsPath = `${apiPath}/orgs`;
export const apiSSOPath = `${apiPath}/sso`;
export const apiUsageLimitsPath = `${apiPath}/usage-limits`;
export const apiTTLSettingsPath = `${apiPath}/ttl-settings`;
export const apiOrgTTLSettingsPath = `${apiOrganizationsPath}/ttl-settings`;
export const apiFeedbackPath = `${apiPath}/feedback`;
export const apiFeedbackConfigsPath = `${apiPath}/feedback-configs`;
export const apiModelPriceMapPath = `${apiPath}/model-price-map`;
export const apiSessionsPath = `${apiPath}/sessions`;
export const apiDatasetsPath = `${apiPath}/datasets`;
export const apiDatasetsUploadPath = `${apiPath}/datasets/upload`;
export const apiExamplesPath = `${apiPath}/examples`;
export const apiExamplesCountPath = `${apiPath}/examples/count`;
export const apiBulkExamplesPath = `${apiPath}/examples/bulk`;
export const apiRunsPath = `${apiPath}/runs`;
export const apiAlertsPath = `${platformApiPath}/alerts`;
export const apiACEPath = `${apiPath}/ace`;
export const apiPublicPath = `${apiPath}/public`;
export const apiKeyPath = `${apiPath}/api-key`;
export const apiAnnotationQueuesPath = `${apiPath}/annotation-queues`;
export const apiComparativeExperimentsPath = `${apiDatasetsPath}/comparative`;
export const apiPlaygroundSettingsPath = `${apiPath}/playground-settings`;
export const apiPlaygroundPath = `${apiPath}/playground`;
export const promptCanvasApiPath = `${platformApiPath}/prompt-canvas`;
export const platformDatasetApiPath = `${platformApiPath}/datasets`;

export const apiPromptsPath = `${apiPath}/prompts`;
export const hubApiReposPath = `${apiPath}/repos`;
export const hubApiLikesPath = `${apiPath}/likes`;
export const hubApiCommitsPath = `${apiPath}/commits`;
export const hubApiSettingsPath = `${apiPath}/settings`;
export const hubApiEventsPath = `${apiPath}/events`;
export const hubApiCommentsPath = `${apiPath}/comments`;

export const apiChartsPath = (isOrgCharts = false) =>
  `${apiPath}/${isOrgCharts ? 'org-charts' : 'charts'}`;
export const apiChartsSectionPath = (isOrgCharts = false) =>
  `${apiChartsPath(isOrgCharts)}/section`;

export const apiPromptOptimizationJobPath = 'optimization-jobs';
export const apiAgentMarketplacePath = '/registry';

export const appIndexPath = '/';
export const appProjectsPath = 'projects';
export const appDatasetsPath = 'datasets';
export const appSettingsPath = 'settings';
export const appPublicPath = 'public';
export const appPlaygroundPath = 'playground';
export const appAnnotationQueuesPath = 'annotation-queues';
export const appAnnotationQueuesBulkViewPath = 'list';
export const appIdentityAnnotationQueuesPath = 'status';

export const appOrganizationPath = 'o';
export const appSessionPath = 'p';
export const appRunPath = 'r';
export const appThreadPath = 't';
export const appExamplePath = 'e';
export const appPublicDatasetsPath = 'd';
export const appComparePath = 'compare';

// including starting slash for hub vars
// because they're all for routing
export const appHubIndexPath = 'hub';
export const appPromptsIndexPath = 'prompts';
export const appGraphIndexPath = 'studio';
export const appAgentMarketplacePath = 'agent-marketplace';

export const appDashboardsIndexPath = 'dashboards';

// All env variables we need to replace at runtime must be strings to avoid undesired tree shaking.
export const hostPrivateRepoEnabled =
  window.host_private_repo_enabled ||
  import.meta.env.VITE_HOST_PRIVATE_REPO_ENABLED ||
  '0';
export const hostEnabled =
  window.host_enabled || import.meta.env.VITE_HOST_ENABLED || '0';

export const hostBackendUrl =
  import.meta.env.VITE_HOST_URL ?? 'http://127.0.0.1:1986';
export const hostApiPath =
  hostBackendUrl + (singleOriginEnabled === '1' ? '/api-host/v1' : '/v1');
export const hostApiProjectsPath = `${hostApiPath}/projects`;
export const hostAppIndexPath = 'host';
export const hostAppRevisionPath = 'r';
export const stripeKey = import.meta.env.VITE_STRIPE_KEY;
export const isPaymentEnabled = import.meta.env.VITE_STRIPE_KEY != null;

export const datadogClientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
export const datadogSite = import.meta.env.VITE_DATADOG_SITE;
export const datadogEnv = import.meta.env.VITE_DATADOG_ENV;
export const datadogVersion = import.meta.env.VITE_DATADOG_VERSION;

export const datadogClientTokenRUM = import.meta.env
  .VITE_DATADOG_CLIENT_TOKEN_RUM;
export const datadogAppTokenRUM = import.meta.env.VITE_DATADOG_APP_TOKEN_RUM;

export function getPlaygroundPath(dataset: boolean) {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  const localAlternateApiPlaygroundPath = 'http://localhost:1988/playground';

  if (local) {
    return dataset ? apiDatasetsPath : localAlternateApiPlaygroundPath;
  }
  return dataset ? apiDatasetsPath : apiPlaygroundPath;
}

const getLocalOrProdPath = (
  localPath: string,
  prodPath: string,
  useCustomProdPath?: string
) => {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  if (local) {
    return localPath;
  }
  return useCustomProdPath || prodPath;
};

export const getPromptCanvasPath = (path: string) => {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  const localAlternatePromptCanvasApiPath =
    'http://localhost:8080/v1/platform/prompt-canvas';
  if (local) {
    return `${localAlternatePromptCanvasApiPath}/${path}`;
  }
  return `${promptCanvasApiPath}/${path}`;
};

export const getExamplesMultiPartPath = (datasetId: string) => {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  const localAlternatExamplesMultiPartPath = `http://localhost:8080/v1/platform/datasets/${datasetId}/examples`;
  if (local) {
    return `${localAlternatExamplesMultiPartPath}`;
  }
  return `${platformApiPath}/datasets/${datasetId}/examples`;
};

export const getAgentMarketplacePath = () => {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  const localAlternateAgentMarketplacePath = 'http://localhost:8080/registry';

  if (local) {
    return localAlternateAgentMarketplacePath;
  }
  return `${backendUrl}/registry`;
};

export const getAgentMarketplaceGatewayUrl = () => {
  const local = import.meta.env.VITE_BACKEND_URL === 'http://localhost:1984';
  const localAlternateAgentMarketplacePath =
    'http://localhost:8080/marketplace';

  if (local) {
    return localAlternateAgentMarketplacePath;
  }
  return import.meta.env.VITE_MARKETPLACE_GATEWAY_URL;
};

export const getAlertsPath = () =>
  getLocalOrProdPath(
    'http://localhost:8080/v1/platform/alerts',
    `${platformApiPath}/alerts`
  );

export const tracingProjectsName = 'Tracing Projects';
export const datasetsName = 'Datasets & Experiments';
