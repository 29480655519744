import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
  useRouteError,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BaseApp } from '@/BaseApp';

import { ExpandableErrorAlert } from './components/ExpandableErrorAlert';
import { Logo } from './components/Logo/Logo';
import './index.css';
import { appGraphIndexPath } from './utils/constants';
import { desktopDatadogLogs } from './utils/datadog/datadog.desktop';

function isError(error: unknown): error is Error {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof error.message === 'string'
  );
}

// eslint-disable-next-line react-refresh/only-export-components
function ErrorElement() {
  const error = useRouteError();

  const [uuid, setUuid] = useState('');
  useEffect(() => {
    if (isError(error) && desktopDatadogLogs) {
      const crashId = uuidv4();
      setUuid(crashId);

      desktopDatadogLogs.logger?.error(
        error.message || 'Page crash',
        { crash_id: crashId },
        error
      );
    } else {
      setUuid('');
    }
  }, [error]);

  return (
    <div className="fixed z-0 flex h-screen w-screen items-center justify-center bg-black/10 dark:bg-background">
      <main className="mx-4 w-full max-w-[480px] rounded-lg border border-secondary bg-popover p-6 text-ls-black">
        <div className="mb-2 text-3xl">
          <Logo />
        </div>
        <h2 className="mb-4 text-lg font-semibold">
          An unexpected error has occurred.
        </h2>
        {uuid && <p className="mb-4">Error ID: {uuid}</p>}

        {isError(error) && (
          <div className="mb-4 max-h-[300px] overflow-y-auto">
            <ExpandableErrorAlert error={`${error.message}`} />
          </div>
        )}
        <p className="mb-4">
          Our team has been notified. Please reach us at{' '}
          <a
            href="mailto:support@langchain.dev"
            className="text-brand-green-400 hover:text-brand-green-400"
          >
            support@langchain.dev
          </a>{' '}
          for further assistance.
        </p>

        <button
          type="button"
          onClick={() => window.location.reload()}
          className="flex w-full items-center justify-center rounded-lg border border-secondary py-2 text-center transition-colors hover:bg-secondary/50 active:bg-secondary"
        >
          Reload page
        </button>
      </main>
    </div>
  );
}

// https://reactrouter.com/en/main/routers/create-browser-router
const router = createBrowserRouter([
  {
    path: '/studio',
    errorElement: <ErrorElement />,
    handle: { public: true },
    lazy: () =>
      import('./Pages/Graph/GraphLayout.standalone').then((m) => ({
        element: (
          <BaseApp>
            <m.GraphLayout />
          </BaseApp>
        ),
      })),
    children: [
      {
        path: '',
        handle: { public: true },
        loader: ({ request }) => {
          const params = new URLSearchParams(request.url.split('?')[1]);
          const query = params.size > 0 ? `?${params.toString()}` : '';
          return redirect(`/${appGraphIndexPath}/thread${query}`);
        },
      },
      {
        path: 'thread/:threadId?',
        handle: { public: true },
        lazy: () =>
          import('./Pages/Graph/GraphThreadPage').then((m) => ({
            element: <m.GraphThreadPage />,
          })),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router} />);
